import { memo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";
import Box from "./Box";

const Logo = ({ color = "white", className, ...props }) => (
  <SVG
    viewBox="0 0 113 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge(
      "w-[113px] h-[34px] text-current fill-current",
      className,
    )}
    {...props}
  >
    <g clipPath="url(#clip0_6382_30427)">
      <Box
        as="path"
        d="M0.460076 28.0175C2.39017 28.8857 4.14132 29.1583 5.7966 29.1583C7.77782 29.1583 8.97294 28.3851 8.97294 27.1493C8.97294 25.9135 7.96316 25.3241 6.07141 24.8235C2.16009 23.8222 0.460076 21.8132 0.460076 18.3911C0.460076 13.6 4.60147 11.1348 9.11355 11.1348C11.6444 11.1348 13.3955 11.5467 14.6801 12.0917L14.3095 17.0222C12.929 16.2934 11.4591 15.9258 9.79739 15.9258C7.95677 15.9258 6.62104 16.699 6.62104 18.0679C6.62104 19.2973 7.58609 20.0324 9.10716 20.3937C13.4339 21.395 15.0892 23.499 15.0892 26.7817C15.0892 31.2559 10.9478 33.9937 5.79021 33.9937C3.86011 33.9937 1.64881 33.5817 -0.00646973 32.8086L0.453685 28.0175H0.460076Z"
        fill="currentColor"
      />
      <Box
        as="path"
        d="M25.0338 14.5569C26.2289 12.2311 28.4849 11.1791 31.0605 11.1791C36.0328 11.1791 38.9726 14.4619 38.9726 18.9361V33.5374H32.9011V21.3506C32.9011 18.5241 31.6102 16.5596 29.0346 16.5596C26.459 16.5596 25.0338 18.7523 25.0338 22.2188V33.5374H18.9623V0H25.0338V14.5569Z"
        fill="currentColor"
      />
      <Box
        as="path"
        d="M64.9267 22.542C64.9267 29.887 60.0056 33.9936 53.8382 33.9936C47.6709 33.9936 42.7498 29.887 42.7498 22.542C42.7498 15.1969 47.6709 11.0903 53.8382 11.0903C60.0056 11.0903 64.9267 15.1526 64.9267 22.542ZM49.1408 22.542C49.1408 27.0161 51.0262 29.342 53.8318 29.342C56.6375 29.342 58.5228 27.0161 58.5228 22.542C58.5228 18.0678 56.6375 15.742 53.8318 15.742C51.0262 15.742 49.1408 18.0234 49.1408 22.542Z"
        fill="currentColor"
      />
      <Box
        as="path"
        d="M68.8827 33.5374V0H74.9542V33.5374H68.8827Z"
        fill="currentColor"
      />
      <Box
        as="path"
        d="M101.189 33.5374H96.0829L95.5333 29.342C94.1528 32.5361 91.4814 33.9937 88.1261 33.9937C82.4189 33.9937 78.923 29.1582 78.923 22.8589C78.923 15.5139 83.1091 11.0841 88.9569 11.0841C91.6284 11.0841 93.9291 12.2248 95.1243 14.2337V0H101.196V33.5374H101.189ZM95.1179 21.1732C95.1179 17.8397 92.6318 15.6977 90.1968 15.6977C87.2952 15.6977 85.1798 17.9791 85.1798 22.4026C85.1798 26.0529 86.7456 28.9745 90.4716 28.9745C92.9577 28.9745 95.1179 26.8768 95.1179 23.3152V21.1732Z"
        fill="currentColor"
      />
      <Box
        as="path"
        d="M112.872 6.07099C112.872 7.85179 111.306 9.26502 109.376 9.26502C107.446 9.26502 105.88 7.85179 105.88 6.07099C105.88 4.29019 107.446 2.87695 109.376 2.87695C111.306 2.87695 112.872 4.29019 112.872 6.07099ZM106.341 11.5465H112.412V33.5372H106.341V11.5465Z"
        fill="currentColor"
      />
      <Box
        as="path"
        d="M109.376 9.87359C111.378 9.87359 113 8.26482 113 6.2803C113 4.29578 111.378 2.68701 109.376 2.68701C107.375 2.68701 105.753 4.29578 105.753 6.2803C105.753 8.26482 107.375 9.87359 109.376 9.87359Z"
        fill="#FEBE34"
      />
    </g>
    <defs>
      <clipPath id="clip0_6382_30427">
        <rect width="113" height="34" fill={color} />
      </clipPath>
    </defs>
  </SVG>
);

Logo.propTypes = { color: PropTypes.string, className: PropTypes.string };

export default memo(Logo);
