import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import Input from "sholdi-primitives/atoms/Input";

import getVariantChildStyles from "sholdi-shared/helpers/getVariantChildStyles";

const variants = {
  adminNavbar: {
    // 684px
    wrapper: "max-w-2xl px-3.5 py-2.5 relative flex-1",
    input: "ml-2.5",
  },
  messengerSearch: {
    input: "placeholder-gray-300 placeholder-text-xs",
  },
  slide: {
    wrapper:
      "flex-row overflow-hidden h-8 py-0 px-2 rounded-full w-8 transition-all duration-300 ease-in-out",
    input: "w-0 pl-6 text-xs placeholder-gray-300 placeholder-text-xs",
  },
};

export const expandedClasses = {
  slide: {
    wrapper: "w-[400px]",
    input: "pl-2 w-auto",
  },
};

const wrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "wrapper"),
  },
});

export const SearchWrapper = memo(({ variant, className, expanded, ...props }) => (
  <div
    className={twMerge(
      className,
      wrapper({ variant }),
      expanded ? expandedClasses[variant]?.wrapper : "",
    )}
    {...props}
  />
));

SearchWrapper.displayName = "SearchWrapper"

SearchWrapper.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

const searchInput = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "input"),
  },
});

export const SearchInput = memo(forwardRef(
  ({ variant, className, expanded, ...props }, ref) => (
    <Input
      className={twMerge(
        className,
        searchInput({ variant }),
        expanded ? expandedClasses[variant]?.input : "",
      )}
      {...props}
      ref={ref}
    />
  ),
));

SearchInput.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};
